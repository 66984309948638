// 换新
<template>
  <div class="TableOrder">
    <div>
      <newShop
        @ClickOrderType="ClickOrderType"
        @ClickDiaRec="ClickDiaRec"
        :partition="partition"
        ref="orderchild"
        :id="iiid"
      />
    </div>
    <el-dialog
      :visible="recVisible"
      @close="recVisible = false"
      title="回收商家报价分析"
      width="1136px"
    >
      <div class="flex_tab">
        <div
          style="display: flex;
    align-items: center;
    justify-content: center;"
          v-for="item in tabList"
          @click="tabclick(item.val)"
          :key="item.val"
          :class="{ active_tab: item.val == tabval }"
        >
          <span>{{ item.name }}</span>
          <el-tooltip
            v-if="item.val == '2'"
            class="item"
            effect="dark"
            content="振幅=商家报价-订单最高报价/最高报价*100%，用来体现各个商家报价与订单最高价的差距。商家振幅越高，报价与最高价差距越大。"
            placement="top"
          >
            <img
              src="../../../assets/images/home/ico_ts_red_.png"
              width="14px"
              height="14px"
              alt=""
            />
          </el-tooltip>
        </div>
      </div>
      <template v-if="tabval == '1'">
        <div style="display: flex;margin-bottom:20px;align-items: center;">
          <div
            style="display: flex; align-items: center;margin-right:20px"
          >
            <span style="font-size: 14px; color: #20a0ff">选择总监/区域：</span>
            <el-select
              v-model="xcxStaffId"
              placeholder="全部"
              clearable
              size="small"
              @change="xcxStaffIdChange"
            >
              <el-option
                v-for="item in partition"
                :key="item.name"
                :value="item.staffId"
                :label="
                  item.areaName
                    ? `${item.staffName}-${item.areaName}`
                    : item.staffName
                "
              >
              </el-option>
            </el-select>
          </div>
          <div style="display: flex;margin-right:20px">
            <el-button
              :type="dateType == '01' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType('01')"
              >今日</el-button
            >
            <el-button
              :type="dateType == '03' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType('03')"
              >本周</el-button
            >
            <el-button
              :type="dateType == '05' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType('05')"
              >本月</el-button
            >
          </div>
          <div style="display: flex; flex: 1;">
            <div class="el-date-time">
              <el-date-picker
                size="small"
                v-model="valueTime"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>
            <div style="margin: 0 5px 0 20px">
              <div class="btnType3" @click="getMerchant">
                <i class="el-icon-search"></i>查询
              </div>
            </div>
          </div>
          <!-- <div class="export_excel" @click="exportBasicsExcel">
            <img
              src="https://image.fanxinghuishou.cn/20221008/840842886.png"
              alt=""
            />
            导出表格
          </div> -->
        </div>
        <div style="margin-bottom: 20px">
          <el-table
            max-height="550"
            :data="tableData"
            border
            style="width: 100%"
            v-loading="analysisLoading"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="merchantName"
              label="回收商"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="inquiry"
              label="接收询价量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quote"
              label="报价量"
              align="center"
            ></el-table-column>
            <el-table-column prop="quoteRatio" label="报价率" align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>报价率</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="报价量/合作门店询价量*100%"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.quoteRatio }}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="zeroQuote" label="0元报价量" align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>0元报价量</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="回收商最终以0元出价的订单量"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.zeroQuote }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteRatio" label="0元报价率" align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>0元报价率</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="回收商最终以0元出价的订单量占全部询价量的比率"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.zeroQuoteRatio }}%</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
            prop="maxQuote"
            label="最高报价(次)"
            align="center"
          ></el-table-column> -->
            <el-table-column align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>最高报价量</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="选择时间下门店商家成交订单中，该回收商为最高报价的订单数量"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.maxQuote }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteMaxRatio" label="最高率" align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>最高率</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="最高报价量/报价量*100%"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.quoteMaxRatio }}%</span>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>最高报价占比</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="最高报价量/所有商家最高报价量之和*100%"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.maxQuoteRate }}%</span>
              </template>
            </el-table-column>

            <el-table-column align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>平均报价速度</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="累计报价量的报价时长总和/累计报价量，保留2位小数"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.avgSpeed }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center">
              <template slot="header">
                <div class="img-el-heard">
                  <div>平均报价(元)</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="累计报价的价格总和/累计报价量，保留2位小数"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/home/ico_ts_red_.png"
                        width="14px"
                        height="14px"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.avgQuoteAmount }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-if="tabval == '2'">
        <div style="display: flex;margin-bottom:20px">
          <div
            style="display: flex; align-items: center;margin-right:20px"
          >
            <span style="font-size: 14px; color: #20a0ff">选择总监/区域：</span>
            <el-select
              v-model="amplitudeForm.xcxStaffId"
              placeholder="全部"
              clearable
              size="small"
              @change="xcxStaffId2Change"
            >
              <el-option
                v-for="item in partition"
                :key="item.name"
                :value="item.staffId"
                :label="
                  item.areaName
                    ? `${item.staffName}-${item.areaName}`
                    : item.staffName
                "
              >
              </el-option>
            </el-select>
          </div>
          <div style="display: flex;margin-right:20px">
            <el-button
              :type="amplitudeForm.dateType == '01' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType2('01')"
              >今日</el-button
            >
            <el-button
              :type="amplitudeForm.dateType == '03' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType2('03')"
              >本周</el-button
            >
            <el-button
              :type="amplitudeForm.dateType == '05' ? 'primary' : 'default'"
              size="small"
              @click="ClickDateType2('05')"
              >本月</el-button
            >
          </div>
          <div style="display: flex;">
            <div class="el-date-time">
              <el-date-picker
                size="small"
                v-model="valueTime2"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>
            <div style="margin: 0 5px 0 20px">
              <div class="btnType3" @click="getMerchantAmplitude">
                <i class="el-icon-search"></i>查询
              </div>
            </div>
          </div>
        </div>
        <div class="offer-box" style="margin-bottom: 20px">
          <div>旧机最高报价</div>
          <div class="offer-list">
            <div
              class="offer-item"
              :class="{ active: item.text == offerSelect }"
              v-for="(item, index) in offerList"
              :key="index"
              @click="selectOffer(item)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <el-table
            max-height="550"
            :data="amplitudeDatalist"
            border
            style="width: 100%"
            v-loading="amplitudeLoading"
          >
            <el-table-column prop="merchantName" label="回收商" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.merchantName"
                  placement="top"
                >
                  <div class="Remarks">
                    <span>{{ row.merchantName }}</span>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="5%≤振幅<10%" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.fiveToTen > 0"
                  class="is-click"
                  @click="toOrderList(row, [5, 10])"
                  >{{ row.fiveToTen }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
            <el-table-column label="10%≤振幅<20%" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.fenToTwenty > 0"
                  class="is-click"
                  @click="toOrderList(row, [10, 20])"
                  >{{ row.fenToTwenty }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
            <el-table-column label="20%≤振幅<30%" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.twentyToThirty > 0"
                  class="is-click"
                  @click="toOrderList(row, [20, 30])"
                  >{{ row.twentyToThirty }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
            <el-table-column label="30%≤振幅<40%" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.thirtyToForty > 0"
                  class="is-click"
                  @click="toOrderList(row, [30, 40])"
                  >{{ row.thirtyToForty }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
            <el-table-column label="40%≤振幅<50%" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.fortyToFifty > 0"
                  class="is-click"
                  @click="toOrderList(row, [40, 50])"
                  >{{ row.fortyToFifty }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
            <el-table-column label="振幅50%以上" align="center">
              <template slot-scope="{ row }">
                <span
                  v-if="row.moreThanFifty > 0"
                  class="is-click"
                  @click="toOrderList(row, [50])"
                  >{{ row.moreThanFifty }}单</span
                >
                <span v-else>0单</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-bottom: 20px">
          振幅越高，价差越大；回收商在对应振幅下的单量越多，说明商家报价出现的价差在该振幅下的次数越多。
        </div>
      </template>
    </el-dialog>
    <DialogTbale
      @onClose="dialogOrderClose"
      :show="dialogOrderShow"
      :title="'订单列表-' + orderName"
      :loading="dialogOrderLoading"
      :columns="dialogOrderTableColumns"
      :data="dialogOrderList"
      :currentPage="pageson.pageNum"
      :total="pageson.total"
      :pageSize="10"
      :width="1000"
      @handleCurrentChange="handleCurrentChangeDialog"
    >
      <el-table-column label="订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <span class="is-click" @click="toOrderDetails(row)">{{
            row.orderNo
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回收商品" slot="phoneName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.phoneName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="询价门店" slot="storeName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.storeName"
            class="item"
            effect="dark"
            :content="row.storeName"
            placement="top"
          >
            <div class="Remarks">
              <span>{{ row.storeName }}</span>
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="询价人员" slot="staffName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.staffName }}-{{ row.staffMobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最高报价" slot="maxPrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.maxPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="报价差额/报价振幅"
        slot="quoteAmplitude"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.quoteAmplitudePrice }}/{{ row.quoteAmplitude }}%</span>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>
<script>
import newShop from "./table/newShop";
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";
export default {
  name: "TableNewShop",
  props: ["partition", "iiid"],
  components: { newShop },
  data() {
    return {
      recVisible: false,
      analysisLoading: false,
      amplitudeLoading: false,
      dialogOrderLoading: false,
      dialogOrderShow: false,
      tableData: [],
      amplitudeDatalist: [],
      dialogOrderList: [],
      dateType: "01",
      valueTime: "",
      valueTime2: "",
      orderType: "01",
      xcxStaffId: "",
      orderName: "",
      orderMerchantId: "",
      amplitudeForm: {
        startTime: "",
        endTime: "",
        dateType: "01",
        maxQuoteEnd: 500,
        maxQuoteStart: 1,
        merchantId: "",
        xcxStaffId: "",
      },
      amplitudeEnd: "",
      amplitudeStart: "",
      pageson: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      offerSelect: "1-500",
      dialogOrderTableColumns: [
        { slotName: "orderNo" },
        { slotName: "phoneName" },
        { label: "规格", prop: "spec" },
        { slotName: "storeName" },
        { slotName: "staffName" },
        { label: "下单时间", prop: "orderTime" },
        { label: "报价师", prop: "quoteStaffName" },
        { label: "报价", prop: "quotePrice" },
        { slotName: "maxPrice" },
        { slotName: "quoteAmplitude" },
      ],
      offerList: [
        { text: "1-500", val: [1, 500] },
        { text: "500-1000", val: [500, 1000] },
        { text: "1000-2000", val: [1000, 2000] },
        { text: "2000-3000", val: [2000, 3000] },
        { text: "3000-5000", val: [3000, 5000] },
        { text: "5000-8000", val: [5000, 8000] },
        { text: "8000以上", val: [8000] },
      ],
      tabval: "1",
      tabList: [
        {
          name: "基础数据分析",
          val: "1",
        },
        {
          name: "报价振幅分析",
          val: "2",
        },
      ],
    };
  },
  created() {
    // this.getMerchant()
  },
  methods: {
    tabclick(val) {
      if (this.tabval == val) {
        return;
      }
      this.tabval = val;
      if (this.tabval == "1") {
        this.getMerchant();
      } else {
        this.getMerchantAmplitude();
      }
    },
    exportExcel() {
      this.$refs.orderchild.exportExcel();
    },
    ClickDiaRec() {
      this.tabval = "1";
      this.recVisible = true;
      this.getMerchant();
    },
    getMerchant() {
      if (this.valueTime && this.valueTime.length) {
        this.dateType = "";
      } else if (!this.dateType) {
        this.dateType = "01";
      }
      const params = {
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        xcxStaffId: this.xcxStaffId,
        startTime: this.valueTime ? this.valueTime[0] : "",
      };
      this.analysisLoading = true;
      _api.getMerchantRankInStore(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.analysisLoading = false;
      });
    },
    getMerchantAmplitude() {
      if (this.valueTime2 && this.valueTime2.length) {
        this.amplitudeForm.dateType = "";
      } else if (!this.amplitudeForm.dateType) {
        this.amplitudeForm.dateType = "01";
      }
      let params = {
        dateType: this.amplitudeForm.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime2 ? this.valueTime2[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        xcxStaffId: this.amplitudeForm.xcxStaffId,
        startTime: this.valueTime2 ? this.valueTime2[0] : "",
        maxQuoteStart: this.amplitudeForm.maxQuoteStart,
        maxQuoteEnd: this.amplitudeForm.maxQuoteEnd,
      };
      this.amplitudeLoading = true;
      _api.getMerchantAmplitude(params).then((res) => {
        console.log(res);
        this.amplitudeDatalist = res.data;
        this.amplitudeLoading = false;
      });
    },
    // 订单列表
    handleCurrentChangeDialog(val) {
      if (val) {
        this.pageson.pageNum = val;
      }
      let params = {
        merchantId: this.orderMerchantId,
        amplitudeStart: this.amplitudeStart,
        amplitudeEnd: this.amplitudeEnd,
        dateType: this.amplitudeForm.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime2 ? this.valueTime2[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        xcxStaffId: this.amplitudeForm.xcxStaffId,
        startTime: this.valueTime2 ? this.valueTime2[0] : "",
        maxQuoteStart: this.amplitudeForm.maxQuoteStart,
        maxQuoteEnd: this.amplitudeForm.maxQuoteEnd,
        pageNum: this.pageson.pageNum,
        pageSize: this.pageson.pageSize,
      };
      this.dialogOrderLoading = true;
      _api.getMerchantAmplitudeOrder(params).then((res) => {
        this.dialogOrderList = res.data.records;
        this.pageson.total = res.data.total;
        this.dialogOrderLoading = false;
      });
    },
    ClickOrderType(val) {
      console.log(val);
      this.orderType = val;
    },
    ClickDateType(val) {
      this.valueTime = "";
      this.dateType = val;
      this.getMerchant();
    },
    ClickDateType2(val) {
      this.amplitudeForm.valueTime = "";
      this.amplitudeForm.dateType = val;
      this.getMerchantAmplitude();
    },
    xcxStaffIdChange(val) {
      this.getMerchant();
    },
    xcxStaffId2Change(val) {
      this.getMerchantAmplitude();
    },
    // 选择价位区间
    selectOffer(item) {
      this.offerSelect = item.text;
      this.amplitudeForm.maxQuoteStart = item.val[0];
      this.amplitudeForm.maxQuoteEnd = item.val[1] || "";
      this.getMerchantAmplitude();
    },
    // 查看订单列表
    toOrderList(row, arr) {
      this.orderName = row.merchantName;
      this.orderMerchantId = row.id;
      this.dialogOrderShow = true;
      this.amplitudeStart = arr[0];
      this.amplitudeEnd = arr[1] || "";
      this.handleCurrentChangeDialog(1);
    },
    dialogOrderClose() {
      this.dialogOrderShow = false;
      this.amplitudeStart = "";
      this.amplitudeEnd = "";
      this.orderMerchantId = "";
    },
    toOrderDetails(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 导出基础数据分析方法
    exportBasicsExcel() {

      let params = {
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        xcxStaffId: this.xcxStaffId,
        startTime: this.valueTime ? this.valueTime[0] : "",
      };
      _api.getMerchantRankInStoreExcel(params).then((res) => {
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            let start = ""
            let end = ""
            if (!params.dateType) {
              start = moment(Number(params.startTime)).format("YYYY.MM.DD");
              end = moment(Number(params.endTime)).format("YYYY.MM.DD");
            }else{
              switch (params.dateType) {
                case '01':
                  start = moment().startOf("day").format('YYYY.MM.DD');
                  end = moment().startOf("day").format('YYYY.MM.DD');
                  break;
                case '03':
                  start = moment().weekday(1).format('YYYY.MM.DD');
                  end = moment().startOf("day").format('YYYY.MM.DD');
                  break;
                case '05':
                  start = moment().startOf("month").format('YYYY.MM.DD');
                  end = moment().startOf("day").format('YYYY.MM.DD');
                  break;
                default:
                  break;
              }
            }
            let fileName = `【基础数据分析】${start || ""}${
                end ? "-" + end : ""
              }.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.img-el-heard {
  display: flex;
  justify-content: center;
  img {
    margin: 0px 0 -3px 3px;
  }
}
.TableOrder {
  width: 100%;
  // min-height: 360px;
  padding-bottom: 50px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f6f6f6;
    .icon_title {
      display: flex;
      .case-title_icon {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
      .case-title_max {
        display: inline-block;
        height: 17px;
        line-height: 22px;
        font-size: 24px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .case-title_rig {
    float: right;
    height: 18px;
    font-size: 16px;
    font-family: FZLanTingHei-DB-GBK;
    font-weight: 400;
    text-decoration: underline;
    color: #03b6f9;
    line-height: 22px;
    cursor: pointer;
  }
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    width: 140px;
    height: 44px;
    border-radius: 10px;
    line-height: 44px;
  }
  .btnType-after1 {
    background: #ffffff;
    color: #0981ff;
    width: 140px;
    height: 44px;
    border-radius: 10px;
    line-height: 44px;
  }
  .btnType2 {
    width: 100px;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    width: 100px;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    font-size: 14px;
  }
  .el-date-time {
    /deep/ .el-input__inner {
      width: 306px !important;
    }
    /deep/ .el-range-separator {
      width: 10% !important;
    }
  }
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .flex_tab {
    margin-top: -20px;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    > div {
      font-size: 16px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 600;
      color: #8492a6;
      padding: 0 16px;
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .active_tab {
    color: #333333 !important;
  }
  .active_tab:after {
    content: "";
    width: 90px;
    height: 4px;
    background: #20a0ff;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  // /deep/.el-dialog .el-dialog__body {
  //   padding-top: 0 !important;
  // }
  .offer-box {
    display: flex;
    align-items: center;
    color: #20a0ff;
    font-size: 14px;
    .offer-list {
      user-select: none;

      display: flex;
      align-items: center;
    }
    .offer-item {
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      border-radius: 15px;
      border: 1px solid #20a0ff;
      box-sizing: border-box;
      margin-left: 20px;
      cursor: pointer;
    }
    .offer-item.active {
      background: #20a0ff;
      color: #fff;
    }
  }
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; //控制行数
    overflow: hidden;
  }
  .is-click {
    cursor: pointer;
    color: rgba(9, 129, 255, 1);
    border-bottom: 1px solid rgba(9, 129, 255, 1);
  }
  .export_excel {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    > img {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
    width: 92px;
    height: 30px;
    background: #50cd85;
    border-radius: 4px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
