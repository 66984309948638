// 员工
<template>
  <div class="TableInquiry">
    <div>
      <newStaff :id="iiid" :partition="partition" ref="inquirychild" />
    </div>
  </div>
</template>
<script>
import newStaff from "./table/newStaff";
export default {
  name: "TableNewStaff",
  components: { newStaff },
  props: ["iiid", "partition"],
  data() {
    return {};
  },
  created() {},
  methods: {
    exportExcel() {
      this.$refs.inquirychild.exportExcel();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableInquiry {
  width: 100%;
  min-height: 360px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f6f6f6;
    .icon_title {
      display: flex;
      .case-title_icon {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
      .case-title_max {
        display: inline-block;
        height: 17px;
        line-height: 22px;
        font-size: 24px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}
</style>
