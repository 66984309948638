<template>
  <div class="TableOrder">
    <!-- <div class="case-title">
        <div class="icon_title">
          <div>
            <img class="case-title_icon" src="@/assets/home/icon_jyph___.png" alt />
          </div>
          <div class="case-title_max">各门店交易数据统计</div>
        </div>
      </div> -->
    <div>
      <phoneTable :partition="partition" :iid="iiid" ref="phonetablechild" />
    </div>
  </div>
</template>
<script>
import phoneTable from "./table/phoneTable";
export default {
  name: "TableOrder",
  components: { phoneTable },
  props: ["partition","iiid"],
  data() {
    return {
      compyid: 0,
    };
  },
  created() {
    this.compyid = this.iiid;
  },
  methods: {
    exportExcel() {
      this.$refs.phonetablechild.exportExcel();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableOrder {
  width: 100%;
  padding-bottom: 50px;
  min-height: 360px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
}
</style>
