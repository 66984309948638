<template>
  <div class="topTitle">
    <div class="topTitle_top">
      <div class="left">
        <div>
          <img class="case-title_ico" src="@/assets/images/icon_th@2x.png" alt />
        </div>
        <div>
          <div>温馨提示，您的余额已不足100元，请及时联系管理员续费，余额为0时将不能进行回收订单管理</div>
          <div>官方咨询电话：028-123456789</div>
        </div>
      </div>
      <div class="right">
        <div>
          <img class="case-title_ico" src="@/assets/images/icon_lxgl@2x.png" alt />
        </div>
        <div class="Contact_maneg" @click="ContactManeg">联系管理员</div>
      </div>
    </div>

    <DialongHome
      :QualityDialogFlag.sync="QualityDialogFlag"
      :title="title"
      :successBtn="successBtn"
      :closeBtn="closeBtn"
      :width="width"
      :successBtnVisible="successBtnVisible"
      @QualityDialogClose="QualityDialogClose"
    >
      <div class="Diaolong_cancat">
        <div class="cancat_img">
          <img src="@/assets/images/icon_lxgl@2x.png" alt />
        </div>
        <div class="cancat_title">
          <div>管理员：张明</div>
          <div>电话：18888880000</div>
        </div>
      </div>
    </DialongHome>
  </div>
</template>
<script>
export default {
  name: "topTitle",
  data() {
    return {
      QualityDialogFlag: false,
      title: "联系管理员",
      width: 380,
      successBtn: "", //弹窗按钮
      closeBtn: "关闭", //弹窗关闭按钮
      successBtnVisible: false //弹窗按钮显示
    };
  },
  methods: {
    ContactManeg() {
      this.QualityDialogFlag = true;
    },
    QualityDialogClose() {
      this.QualityDialogFlag = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.topTitle {
  .Diaolong_cancat {
    width: 300px;
    display: flex;
    justify-content: left;
    align-items: center;
    .cancat_img {
      width: 56px;
      height: 56px;
      img{
          width: 56px;
      height: 56px;
      }
    }
    .cancat_title {
      margin-left: 30px;
      div{
          margin: 10px 0;
      }
    }
  }
  .topTitle_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #ffebeb;
    border-radius: 25px;
    div:first-child {
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .case-title_ico {
        width: 22px;
        height: 22px;
      }
    }
    div:last-child {
      margin-left: 5px;

      div:first-child {
        height: 15px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
      }
      div:last-child {
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #242d3e;
      }
    }
    .right {
      display: flex;
      margin-right: 15px;
      .Contact_maneg {
        cursor: pointer;
      }
    }
  }
}
</style>
