<template>
  <div class="phoneTable">
    <div style="display: flex; margin: 10px 0 0px 0">
      <div
        class="btn"
        :class="this.type == '02' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('02')"
      >
        旧机品牌排行
      </div>
      <div
        class="btn"
        :class="this.type == '03' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('03')"
      >
        旧机机型排行
      </div>
      <div
        class="btn"
        :class="this.type == '01' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('01')"
      >
        成交价位排行
      </div>
    </div>
    <el-form inline size="small" style="margin-top: 20px">
      <el-form-item v-if="['02', '03'].includes(type)" label="选择分类">
        <el-select v-model="formData.typeId" filterable clearable>
          <el-option v-for="item in classifyList" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <template v-if="type == '03'">
        <el-form-item label="选择品牌">
          <el-select v-model="formData.brandNo" filterable clearable>
            <el-option v-for="item in brandList" :key="item.brandNo" :value="item.brandNo" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="选择机型">
          <el-select v-model="formData.modelId" :disabled="!formData.typeId || !formData.brandNo" filterable clearable placeholder="请先选择分类和品牌">
            <el-option v-for="item in modelList" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
      </template>
      <el-form-item>
        <el-select v-model="formData.xcxStaffId" placeholder="请选择总监/区域" clearable>
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="item.areaName ? `${item.staffName}-${item.areaName}` : item.staffName"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formData.storeId" placeholder="请选择所属门店" clearable filterable>
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          v-for="item in dateTypeOption"
          :key="item.value"
          class="btn"
          :type="formData.dateType == item.value ? 'primary' : 'default'"
          @click="clickDateType(item.value)"
        >{{item.label}}</el-button>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formData.timeRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <span class="add_time" @click="addTimeChoose">{{addTimeText}}</span>
        <el-date-picker
          style="margin-left: 30px;"
          v-model="thanValueTime"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="dateThenTimeChange"
          v-if="addTimeTrue"
          >
        </el-date-picker>
        <span @click="addTimeCancle" v-if="addTimeTrue == true"><img src="../../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="handleCurrentChange()">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="profit_table">
      <el-table
        max-height="550"
        v-loading="profitLoading"
        @sort-change="sortTableRank"
        :data="tableData"
        border
        style="width: 100%"
        :default-sort = "{prop: 'inquiryCount.chooseData', order: 'descending'}"
      >
        <el-table-column label="排序" type="index" align="center" width="70">
          <template slot-scope="{ $index }">
            <div class="numBG_1" v-if="$index === 0">1</div>
            <div class="numBG_2" v-else-if="$index === 1">2</div>
            <div class="numBG_3" v-else-if="$index === 2">3</div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataName"
          :label="nameObj[type]"
          align="center"
          width="70"
        >
          <template slot-scope="{row}">
            <div class="Remarks">
                <span
                  style="
                cursor: pointer;
                color: rgba(9, 129, 255, 1);
                border-bottom: 1px solid rgba(9, 129, 255, 1);
              "
                  @click="onShowRank(row)"
                  >{{row.dataName }}</span
                >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="inquiryCount.chooseData"
          label="询价量"
          align="center"
          sortable :sort-orders="['descending', 'ascending']"
          width="90"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.inquiryCount.chooseData }}</span>
          <div v-if="scope.row.inquiryCount.thanUp">
            <div v-if="scope.row.inquiryCount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.inquiryCount.thanData }}%</div>
            <div v-else-if="scope.row.inquiryCount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.inquiryCount.thanData }}%</div>
            <div v-else-if="scope.row.inquiryCount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.inquiryCount.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
        <el-table-column
          prop="dataCount.chooseData"
          label="成交量"
          align="center"
          sortable :sort-orders="['descending', 'ascending']"
          width="90"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.dataCount.chooseData }}</span>
          <div v-if="scope.row.dataCount.thanUp">
            <div v-if="scope.row.dataCount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dataCount.thanData }}%</div>
            <div v-else-if="scope.row.dataCount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dataCount.thanData }}%</div>
            <div v-else-if="scope.row.dataCount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dataCount.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="dataRate.chooseData" label="成交率" width="90" align="center" sortable :sort-orders="['descending', 'ascending']">
          <!-- <template slot-scope="{ row }"> {{ row.dataRate }}%</template> -->
          <template slot-scope="scope">
          <span>{{ scope.row.dataRate.chooseData }}%</span>
          <div v-if="scope.row.dataRate.thanUp">
            <div v-if="scope.row.dataRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dataRate.thanData }}%</div>
            <div v-else-if="scope.row.dataRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dataRate.thanData }}%</div>
            <div v-else-if="scope.row.dataRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dataRate.thanData }}%</div>
          </div>
        </template>
        </el-table-column>
        <el-table-column prop="dealAmount.chooseData" label="回收金额" width="100" align="center" sortable :sort-orders="['descending', 'ascending']">
          <!-- <template slot-scope="{ row }"> {{ row.dealAmount }}</template> -->
          <template slot-scope="scope">
          <span>{{ scope.row.dealAmount.chooseData }}</span>
          <div v-if="scope.row.dealAmount.thanUp">
            <div v-if="scope.row.dealAmount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
          </div>
        </template>
        </el-table-column>
        <el-table-column prop="finalPrice.chooseData" label="顾客成交金额" width="120" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.finalPrice.chooseData }}</span>
            <div v-if="scope.row.finalPrice.thanUp">
              <div v-if="scope.row.finalPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.finalPrice.thanData }}%</div>
              <div v-else-if="scope.row.finalPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.finalPrice.thanData }}%</div>
              <div v-else-if="scope.row.finalPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.finalPrice.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="useAddPrice.chooseData" label="加价金额" width="100" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
          <span>{{ scope.row.useAddPrice.chooseData }}</span>
          <div v-if="scope.row.useAddPrice.thanUp">
            <div v-if="scope.row.useAddPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
          </div>
        </template>
        </el-table-column> -->
        <el-table-column prop="dataProfit.chooseData" label="总毛利" align="center" width="100" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.dataProfit.chooseData }}</span>
            <div v-if="scope.row.dataProfit.thanUp">
              <div v-if="scope.row.dataProfit.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dataProfit.thanData }}%</div>
              <div v-else-if="scope.row.dataProfit.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dataProfit.thanData }}%</div>
              <div v-else-if="scope.row.dataProfit.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dataProfit.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="netRebateAmount.chooseData" label="公司净利" align="center" width="100" sortable :sort-orders="['descending', 'ascending']">
          <!-- <template slot-scope="{ row }"> {{ row.netRebateAmount }} </template> -->
          <template slot-scope="scope">
            <span>{{ scope.row.netRebateAmount.chooseData }}</span>
            <div v-if="scope.row.netRebateAmount.thanUp">
              <div v-if="scope.row.netRebateAmount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.netRebateAmount.thanData }}%</div>
              <div v-else-if="scope.row.netRebateAmount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.netRebateAmount.thanData }}%</div>
              <div v-else-if="scope.row.netRebateAmount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.netRebateAmount.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="staffReward.chooseData" label="员工奖励" align="center" width="100" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.staffReward.chooseData }}</span>
            <div v-if="scope.row.staffReward.thanUp">
              <div v-if="scope.row.staffReward.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
              <div v-else-if="scope.row.staffReward.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
              <div v-else-if="scope.row.staffReward.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rebateAfter.chooseData" label="公司返利利润" align="center" width="120" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.rebateAfter.chooseData }}</span>
            <div v-if="scope.row.rebateAfter.thanUp">
              <div v-if="scope.row.rebateAfter.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
              <div v-else-if="scope.row.rebateAfter.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
              <div v-else-if="scope.row.rebateAfter.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rebateDepreciate.chooseData" label="公司压价利润" align="center" width="120" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.rebateDepreciate.chooseData }}</span>
            <div v-if="scope.row.rebateDepreciate.thanUp">
              <div v-if="scope.row.rebateDepreciate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
              <div v-else-if="scope.row.rebateDepreciate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
              <div v-else-if="scope.row.rebateDepreciate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="staffAngPow.chooseData" label="店员红包" align="center" width="100" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.staffAngPow.chooseData }}</span>
            <div v-if="scope.row.staffAngPow.thanUp">
              <div v-if="scope.row.staffAngPow.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
              <div v-else-if="scope.row.staffAngPow.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
              <div v-else-if="scope.row.staffAngPow.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="staffCutPrice.chooseData" label="压价提成" align="center" width="100" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.staffCutPrice.chooseData }}</span>
            <div v-if="scope.row.staffCutPrice.thanUp">
              <div v-if="scope.row.staffCutPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffCutPrice.thanData }}%</div>
              <div v-else-if="scope.row.staffCutPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffCutPrice.thanData }}%</div>
              <div v-else-if="scope.row.staffCutPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffCutPrice.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="allCutPrice.chooseData" label="总压价金额" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.allCutPrice.chooseData }}</span>
            <div v-if="scope.row.allCutPrice.thanUp">
              <div v-if="scope.row.allCutPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.allCutPrice.thanData }}%</div>
              <div v-else-if="scope.row.allCutPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.allCutPrice.thanData }}%</div>
              <div v-else-if="scope.row.allCutPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.allCutPrice.thanData }}%</div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="renewNum.chooseData" label="以旧换新量" align="center" width="110" sortable :sort-orders="['descending', 'ascending']">
          <!-- <template slot-scope="{ row }"> {{ row.renewNum }}</template> -->
          <template slot-scope="scope">
          <span>{{ scope.row.renewNum.chooseData }}</span>
          <div v-if="scope.row.renewNum.thanUp">
            <div v-if="scope.row.renewNum.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.renewNum.thanData }}%</div>
            <div v-else-if="scope.row.renewNum.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.renewNum.thanData }}%</div>
            <div v-else-if="scope.row.renewNum.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.renewNum.thanData }}%</div>
          </div>
        </template>
        </el-table-column>
        <el-table-column prop="subsidyPrice.chooseData" label="换新补贴金额" width="120" align="center" sortable :sort-orders="['descending', 'ascending']">
          <template slot-scope="scope">
            <span>{{ scope.row.subsidyPrice.chooseData }}</span>
            <div v-if="scope.row.subsidyPrice.thanUp">
              <div v-if="scope.row.subsidyPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.subsidyPrice.thanData }}%</div>
              <div v-else-if="scope.row.subsidyPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.subsidyPrice.thanData }}%</div>
              <div v-else-if="scope.row.subsidyPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.subsidyPrice.thanData }}%</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="rankTitle" :visible.sync="rankDialogShow" :close-on-click-modal="false" width="1150px"
        @closed="rankDialogShow = false">
        <el-form inline size="small" style="margin-top:20px">
          <el-form-item>
            <el-select v-model="dowmFormData.xcxStaffId" placeholder="请选择总监/区域" clearable>
              <el-option
                v-for="item in partition"
                :key="item.name"
                :value="item.staffId"
                :label="item.areaName ? `${item.staffName}-${item.areaName}` : item.staffName"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dowmFormData.storeId" placeholder="请选择所属门店" clearable filterable>
              <el-option
                v-for="item in storeList"
                :key="item.key"
                :value="item.key"
                :label="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-for="item in dateTypeOption"
              :key="item.value"
              class="btn"
              :type="dowmFormData.dateType == item.value ? 'primary' : 'default'"
              @click="clickDowmDateType(item.value)"
            >{{item.label}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="dowmFormData.timeRange"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="handleSearch()">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="filterRankTable"
          max-height="550"
          :data="rankTableData"
          style="width: 100%">
          <el-table-column
              label="序号"
              align="center"
              type="index"
              width="50">
          </el-table-column>
          <el-table-column label="门店" prop="storeName" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.storeName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="询价量" sortable :sort-orders="['descending', 'ascending']" prop="inquiryCount" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.inquiryCount }}</div>
            </template>
          </el-table-column>
          <el-table-column label="成交量" sortable :sort-orders="['descending', 'ascending']" prop="dataCount" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.dataCount }}</div>
            </template>
          </el-table-column>
          <el-table-column label="成交率" sortable :sort-orders="['descending', 'ascending']" prop="dataRate" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.dataRate }}%</div>
            </template>
          </el-table-column>
          <el-table-column label="回收金额" sortable :sort-orders="['descending', 'ascending']" prop="dealAmount" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.dealAmount }}</div>
            </template>
          </el-table-column>
          <el-table-column label="顾客成交金额" sortable :sort-orders="['descending', 'ascending']" prop="finalPrice" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.finalPrice }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="加价金额" sortable :sort-orders="['descending', 'ascending']" prop="useAddPrice" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.useAddPrice }}</div>
            </template>
          </el-table-column> -->
          <el-table-column label="总毛利" sortable :sort-orders="['descending', 'ascending']" prop="dataProfit" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.dataProfit }}</div>
            </template>
          </el-table-column>
          <el-table-column label="公司净利" sortable :sort-orders="['descending', 'ascending']" prop="netRebateAmount" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.netRebateAmount	 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="员工奖励" sortable :sort-orders="['descending', 'ascending']" prop="staffReward" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.staffReward	 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="公司返利利润" sortable :sort-orders="['descending', 'ascending']" prop="rebateAfter" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.rebateAfter	 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="公司压价利润" sortable :sort-orders="['descending', 'ascending']" prop="rebateDepreciate" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.rebateDepreciate	 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="店员红包" sortable :sort-orders="['descending', 'ascending']" prop="staffAngPow" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.staffAngPow	}}</div>
            </template>
          </el-table-column>
          <el-table-column label="压价提成" sortable :sort-orders="['descending', 'ascending']" prop="staffCutPrice" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.staffCutPrice	 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="以旧换新量" sortable :sort-orders="['descending', 'ascending']" prop="renewNum" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.renewNum }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="补贴金额" sortable :sort-orders="['descending', 'ascending']" prop="subsidyPrice" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.subsidyPrice }}</div>
            </template>
          </el-table-column> -->
        </el-table> 
      </el-dialog>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
import { mapState } from "vuex";
export default {
  name: "phoneTable",
  props: ["currdate", "partition", "id"],
  data() {
    return {
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      isThan: false,
      isThanTrue: true,
      thanValueTime: [],
      rankTitle: "",
      queryKey:"",
      rankDialogShow:false,
      rankTableData:[],
      type: "02",
      tableData: [],
      profitLoading: false,
      // storeList: [],
      nameObj: {
        '01': '价位段',
        '02': '品牌',
        '03': '机型',
      },
      dateTypeOption: [
        { label: '今日', value: '01' },
        { label: '本周', value: '03' },
        { label: '本月', value: '05' },
      ],
      formData: {
        dateType: '01',
        startTime: '',
        endTime: '',
        brandNo: '',
        storeId: '',
        typeId: '',
        xcxStaffId: '',
        modelId: '',
        timeRange: []
      },
      dowmFormData: {
        timeRange: []
      },
      tableSort: {
        prop: 'inquiryCount',
        order: 'descending'
      },
      classifyList: [],
      brandList: [],
      modelList: []
    };
  },
  watch: {
    currdate(val) {
      if (val) {
        this.formData.dateType = val
        this.formData.startTime = ''
        this.formData.endTime = ''
        this.handleCurrentChange();
      }
    },
    'formData.typeId'() {
      this.getBrandList()
      this.getModelList()
    },
    'formData.brandNo'() {
      this.getModelList()
    },
    'formData.timeRange'(v) {
      const [startTime, endTime] = v || []
      if (v && v.length) {
        this.formData.dateType = ''
      } else if (!this.formData.dateType) {
        this.formData.dateType = '01'
      }
      this.formData.startTime = startTime
      this.formData.endTime = endTime
    },
    'dowmFormData.timeRange'(v) {
      const [startTime, endTime] = v || []
      if (v && v.length) {
        this.dowmFormData.dateType = ''
      } else if (!this.dowmFormData.dateType) {
        this.dowmFormData.dateType = '01'
      }
      this.dowmFormData.startTime = startTime
      this.dowmFormData.endTime = endTime
    },
  },
  created() {
    this.handleCurrentChange();
    // this.getStoreList();
    this.getClassifyList()
    this.getBrandList()
  },
  computed: {
    ...mapState({
      storeList: (state) => state.tagsView.storeList,
    }),
  },
  methods: {
    addTimeChoose () {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
      this.isThan = true
      this.isThanTrue = true
    },
    addTimeCancle () {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.isThan = false
      this.isThanTrue = true
      this.dateTime = []
      this.thanValueTime = []
      this.dateOn = '01'
    },
    clickDateType(v) {
      this.formData.dateType = v
      this.formData.timeRange = []
      this.handleCurrentChange()
    },
    clickDowmDateType(v) {
      this.dowmFormData.dateType = v
      this.dowmFormData.timeRange = []
      this.handleSearch()
    },
    // getStoreList() {
    //   _api.getStoreSelectList({companyId: this.id}).then((res) => {
    //     this.storeList = res.data;
    //   });
    // },
    getClassifyList() { // 分类
      _api.getAllMachineType().then(res => {
        this.classifyList = res.data
      })
    },
    getBrandList() { // 品牌
      this.brandList = []
      this.formData.brandNo = ''
      _api.selectBrandList({ typeId: this.formData.typeId }).then(res => {
        this.brandList = res.data
      })
    },
    getModelList() { // 机型
      this.formData.modelId = ''
      this.modelList = []
      if (this.formData.typeId && this.formData.brandNo) {
        const brandId = this.brandList.find(item => item.brandNo == this.formData.brandNo)?.id
        _api.listSelected({ machineTypeId: this.formData.typeId, brandId }).then(res => {
          this.modelList = res.data
        })
      }
    },
    onShowRank(val){
      this.queryKey = val.queryKey
      this.rankTitle = `${val.dataName}${this.nameObj[this.type]}-门店排行`
      this.rankTableData = []
      this.rankDialogShow = true
      this.$nextTick(()=>{
        this.$refs.filterRankTable.sort(this.tableSort.prop, this.tableSort.order)
        const { xcxStaffId, storeId, dateType, timeRange } = this.formData
        this.dowmFormData = JSON.parse(JSON.stringify({ xcxStaffId, storeId, dateType, timeRange }))
        this.handleSearch()
      })
    },
    sortTableRank(column){
      const propArr = column.prop.split('.')
      this.tableSort = {
        prop: propArr[0],
        order: column.order,
      }
    },
    // 表格切换页面
    handleCurrentChange() {
      this.profitLoading = true;
      const [startTime, endTime] = this.formData.timeRange || []
      _api
        .rankStatistics({
          ...this.formData,
          startTime, endTime,
          type: this.type,
          companyId: this.id,
          // 列表
          thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
          thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
        })
        .then((res) => {
          if (res.code === 1) {
            this.tableData = res.data;
          }
          this.profitLoading = false;
        })
        .catch((err) => {
          this.profitLoading = false;
        });
    },
    handleSearch() {
      const [startTime, endTime] = this.dowmFormData.timeRange || []
      _api.rankStatisticsDown({
        ...this.dowmFormData,
        startTime, endTime,
        type: this.type,
        companyId: this.id,
        // 弹窗
        queryKey: this.queryKey
      }).then((res) => {
        if (res.code === 1) {
          this.rankTableData = res.data;
        }
      })
    },
    ClickOrderType(val) {
      this.type = val;
      this.handleCurrentChange();
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
 .add_time {
    color: #20A0FF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    cursor: pointer;
  }
  .close_img {
    width: 16px;
    margin-left: 8px;
    margin-top: 2px;
  }
/deep/ .el-input__inner {
  width: 250px !important;
}
/deep/ .el-range-separator {
  width: 10% !important;
}
.phoneTable {
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    height: 32px;
    background: #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    padding: 0 9px;
  }
  .btnType-after1 {
    padding: 0 9px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #20a0ff;
    line-height: 32px;
  }
  .btnType2 {
    width: 100px;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    width: 100px;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    font-size: 14px;
  }
  .btnType4 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 80px;
    height: 36px;
    line-height: 36px;
    background: #0981ff;
    color: white;
    border-radius: 18px;
    font-size: 14px;
  }
  .orderPagina {
    float: right;
    padding: 30px;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png") !important;
    width: 50px !important;
    height: 25px !important;
    color: white !important;
    background-size: 100%;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png") !important;
    width: 50px !important;
    height: 25px !important;
    color: white !important;
    background-size: 100%;

  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png") !important;
    width: 50px !important;
    height: 25px !important;
    color: white !important;
    background-size: 100%;
  }
}
// .profit_table {
//   > div:nth-child(1) {
//     width: 370px;
//     height: 50px;
//     background: linear-gradient(0deg, #ffffff 0%, #e4ecfd 88%);
//     border-radius: 4px 4px 0px 0px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     font-size: 12px;
//     font-family: Microsoft YaHei;
//     font-weight: 400;
//     color: #778ca2;
//     line-height: 34px;
//     padding: 0 12px;
//     box-sizing: border-box;
//     > div:not(:first-child) {
//       flex: 1;
//       text-align: center;
//     }
//     > div:nth-child(1) {
//       width: 36px !important;
//       text-align: center;
//     }
//   }
//   > div:nth-child(2) {
//     max-height: 400px;
//     overflow-y: scroll;
//     > div:nth-child(1) {
//       color: #f09a33 !important;
//     }
//     > div:nth-child(2) {
//       color: #3a8fea !important;
//     }
//     > div:nth-child(3) {
//       color: #eb623b !important;
//     }
//     > div:not(:first-child) {
//       color: #778ca2;
//       font-size: 14px;
//       flex: 1;
//       text-align: center;
//     }
//     // 滚动条整体部分
//     &::-webkit-scrollbar {
//       width: 6px;
//       height: 6px;
//     }
//     // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
//     &::-webkit-scrollbar-button {
//       display: none;
//     }
//     // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
//     &::-webkit-scrollbar-thumb {
//       background: rgba(144, 147, 153, 0.3);
//       cursor: pointer;
//       border-radius: 4px;
//     }
//     // 边角，即两个滚动条的交汇处
//     &::-webkit-scrollbar-corner {
//       display: none;
//     }
//     // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
//     &::-webkit-resizer {
//       display: none;
//     }
//   }
//   .item_list {
//     // width: 370px;
//     height: 50px;
//     background: #ffffff;
//     box-shadow: 0px 2px 4px 0px rgba(182, 185, 208, 0.4);
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0 12px;
//     box-sizing: border-box;
//     margin-bottom: 12px;
//     > div:nth-child(1) {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 36px;
//       .paiming {
//         width: 24px;
//         height: 24px;
//         background: #eff2fa;
//         border-radius: 50%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 12px;
//         font-family: Source Han Sans CN;
//         font-weight: 400;
//         color: #3a8fea;
//       }
//     }
//     > div:not(:first-child) {
//       font-size: 12px;
//       flex: 1;
//       text-align: center;
//     }
//   }
// }
.table_empty {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right {
    height: 32px;
    display: flex;
    justify-content: center;
    line-height: 32px;
    cursor: pointer;
  }
  .opacity-5 {
    opacity: 0.5;
  }
  .opacity-1 {
    opacity: 1 !important;
  }
  .box-icon {
    height: 30px;
    margin-top: 7px;
    .up {
      width: 0px; /*设置宽高为0，所以div的内容为空，从才能形成三角形尖角*/
      height: 0px;
      opacity: 0.5;
      border-bottom: 6px solid #217aff;
      border-left: 4px solid transparent; /*transparent 表示透明*/
      border-right: 4px solid transparent;
      margin-bottom: 4px;
    }
    .down {
      width: 0px;
      height: 0px;
      opacity: 0.5;
      border-top: 6px solid #217aff;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }
  .box-icon div {
    height: 10px;
    margin-left: 4px;
  }  
  
  .equationBlue {
    color: #20A0FF;
  }
  .equationGreen {
    color: #49A915;
  }
  .equationRed {
    color: #FF4949;
  }
  /deep/.el-dialog .el-dialog__body{
    padding: 0 20px 20px;
  }
</style>
