<!--功能情况-->
<template>
  <!-- 成色渲染后，无核心功能，不显示任何数据 -->
  <div v-if="(show && listData.length) || !show">
    <div class="box" v-if="listData.length">
      <div class="inner" v-for="(item, index) in listData" :key="index">
        <p>{{ item.label }}：</p>
        <p class="normal_style" v-if="item.isNormal">{{ item.value }}</p>
        <p style="color: #FF687B" v-else>{{ item.value }}</p>
      </div>
    </div>
    <div v-else class="empty">
      暂无数据
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;

  .inner {
    padding: 10px 0;
    margin-bottom: 10px;
    min-width: 48%;
    width: 48%;
    max-width: 48%;
    background: #F5F6FA;
    border: 1px solid #E4ECFD;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 6px;
    color: #333333;
    font-weight: 700;
  }
}

.normal_style {
  color: #21D8C2;
}

.empty {
  text-align: center;
  padding: 10px 0;
  color: #999999;
}</style>
