
<template>
  <div class="TableOrder">
    <div>
      <newStoreTable :id="iiid" ref="phonetablechild" :partition="partition" />
    </div>
  </div>
</template>
<script>
import newStoreTable from "./table/newStoreTable";
import _api from "@/utils/request";
export default {
  name: "TableNewStore",
  props: ["partition", "iiid"],
  components: { newStoreTable },
  data() {
    return {};
  },
  created() {
    // _api.getNotPInspectorSelect({ scope: "00" }).then((res) => {
    //   console.log(res, "总监区域选择");
    //   this.partition = res.data;
    // });
    console.log(this.partition);
  },
  watch: {},
  methods: {
    exportExcel() {
      this.$refs.phonetablechild.exportExcel();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableOrder {
  width: 100%;
  padding-bottom: 50px;
  min-height: 360px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
}
</style>
